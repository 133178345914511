<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Let's use the Nernst equation to predict the cell potential (emf) of the redox reaction
        shown below under non-standard conditions.
      </p>

      <p class="mb-5 pl-8">
        <stemble-latex
          :content="`$\\ce{Co(s) + Fe^2+(aq, }${conc1.toFixed(2)}
              \\ce{M) -> Co^2+(aq, }${conc2.toFixed(3)}\\ce{M) + Fe(s)}$`"
        />
      </p>

      <p class="mb-2">
        a) First, calculate the standard cell potential for this redox process using the standard
        reduction potentials of each half reaction:
      </p>

      <p class="mb-2 pl-14">
        <stemble-latex
          content="$\ce{Co^2+(aq) -> Co(s)}\hspace{1.0 cm}\text{E}^\circ_\text{red}=-0.280\,\text{V}$"
        />
      </p>

      <p class="mb-5 pl-14">
        <stemble-latex
          content="$\ce{Fe^2+(aq) -> Fe(s)}\hspace{1.1 cm}\text{E}^\circ_\text{red}=-0.447\,\text{V}$"
        />
      </p>

      <calculation-input
        v-model="inputs.Eocell"
        class="mb-5"
        prepend-text="$\text{E}^\circ_\text{cell}:$"
        append-text="$\text{V}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        b) Determine the reaction quotient,
        <stemble-latex content="$\text{Q,}$" />
        for the reaction.
      </p>

      <calculation-input
        v-model="inputs.Qrxn"
        class="mb-5"
        prepend-text="$\text{Q}_\text{rxn}:$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        c) How many electrons are transferred in this reaction?
        <v-select
          v-model="inputs.nElectrons"
          class="d-sm-inline-block ml-3 mr-3"
          style="width: 120px"
          dense
          :items="items"
          placeholder="Electrons"
        />
      </p>

      <p class="mb-2">
        d) Using the Nernst equation, determine the cell potential for the non-standard conditions
        indicated above?
      </p>

      <calculation-input
        v-model="inputs.Ecell"
        prepend-text="$\text{E}_\text{cell}:$"
        append-text="$\text{V}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question427',
  components: {
    CalculationInput,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        Eocell: null,
        Qrxn: null,
        nElectrons: null,
        Ecell: null,
      },
      items: ['1', '2', '3', '4', '5'],
    };
  },
  computed: {
    conc1() {
      return this.taskState.getValueBySymbol('conc1').content.toFloat();
    },
    conc2() {
      return this.taskState.getValueBySymbol('conc2').content.toFloat();
    },
  },
};
</script>
